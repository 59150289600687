import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  CreateDocumentClassBatchGQL,
  CreateOneDocumentClassGQL,
  CreatedDocumentClassGQL,
  DeleteDocumentClassBatchGQL,
  DeleteOneDocumentClassGQL,
  DeletedOneDocumentClassGQL,
  DocumentClassBatchCreateDto,
  DocumentClassCreateDto,
  DocumentClassFilter,
  DocumentClassGQL,
  DocumentClassSort,
  DocumentClassSortFields,
  DocumentClassUpdateDto,
  DocumentClassesByRegexGQL,
  DocumentClassesBySearchTokenGQL,
  DocumentClassesGQL,
  DuplicateDocumentClassTreeGQL,
  ReorderOneDocumentClassGQL,
  RestoreDocumentClassBatchGQL,
  RestoreOneDocumentClassGQL,
  SortDirection,
  SortNulls,
  UpdateDocumentClassBatchGQL,
  UpdateOneDocumentClassGQL,
  UpdatedOneDocumentClassGQL
} from 'src/app/graphql/frontend-data-graphql';

@Injectable({ providedIn: 'root' })
export class DocumentClassService {
  constructor(
    private documentClassesGQL: DocumentClassesGQL,
    private documentClassByRegex: DocumentClassesByRegexGQL,
    private documentClassBySearchToken: DocumentClassesBySearchTokenGQL,
    private documentClassGQL: DocumentClassGQL,
    private updatedGQL: UpdatedOneDocumentClassGQL,
    private createdGQL: CreatedDocumentClassGQL,
    private createGQL: CreateOneDocumentClassGQL,
    private batchCreateGQL: CreateDocumentClassBatchGQL,
    private duplicateOneDocumentClassTree: DuplicateDocumentClassTreeGQL,
    private updateGQL: UpdateOneDocumentClassGQL,
    private batchUpdateGQL: UpdateDocumentClassBatchGQL,
    private deleteGQL: DeleteOneDocumentClassGQL,
    private batchDeleteGQL: DeleteDocumentClassBatchGQL,
    private deletedGQL: DeletedOneDocumentClassGQL,
    private restoreOneDocumentClass: RestoreOneDocumentClassGQL,
    private batchRestoreDocumentClass: RestoreDocumentClassBatchGQL,
    private reorderOneDocumentClass: ReorderOneDocumentClassGQL
  ) {}

  private defaultSort = [
    { field: DocumentClassSortFields.DeletedAt, direction: SortDirection.Asc, nulls: SortNulls.NullsFirst },
    { field: DocumentClassSortFields.OrderWeight, direction: SortDirection.Asc }
  ];

  findMany(
    filter: DocumentClassFilter = {},
    options: {
      withDetail?: boolean;
      withSettings?: boolean;
      pageSize?: number;
      sorting?: DocumentClassSort[];
      childrenFilter?: DocumentClassFilter;
    } = {}
  ) {
    return this.documentClassesGQL.fetch({
      filter,
      paging: { first: options.pageSize ?? 750 },
      sorting: options.sorting ?? this.defaultSort,
      withDetail: options.withDetail ?? true,
      withExportAndPredictorSettings: options.withSettings ?? false,
      simple: !(options.withDetail ?? true),
      childrenFilter: options.childrenFilter ?? {}
    });
  }

  findOne(id: string) {
    return this.documentClassGQL.fetch({ id });
  }

  search(q: string) {
    return this.documentClassBySearchToken.fetch({
      withDetail: false,
      q,
      filter: {},
      paging: { first: 100 },
      sorting: this.defaultSort
    });
  }

  create(input: DocumentClassCreateDto) {
    return this.createGQL.mutate({ input });
  }

  createMany(input: DocumentClassBatchCreateDto, parentIds: string[]) {
    return this.batchCreateGQL.mutate({ input, parentIds });
  }

  updateOne(id: string, update: DocumentClassUpdateDto) {
    return this.updateGQL.mutate({ id, update });
  }

  updateMany(identifiers: string[], update: DocumentClassUpdateDto) {
    return this.batchUpdateGQL.mutate({ identifiers, update });
  }

  deleteOne(id: string) {
    return this.deleteGQL.mutate({ id });
  }

  deleteMany(identifiers: string[]) {
    return this.batchDeleteGQL.mutate({ identifiers });
  }

  restoreOne(id: string) {
    return this.restoreOneDocumentClass.mutate({ id });
  }

  restoreMany(identifiers: string[]) {
    return this.batchRestoreDocumentClass.mutate({ identifiers });
  }

  reorder(id: string, updatedIndex: number) {
    return this.reorderOneDocumentClass.mutate({ id, index: updatedIndex });
  }

  duplicate(referenceId: string, input: DocumentClassCreateDto) {
    return this.duplicateOneDocumentClassTree.mutate({ reference: referenceId, update: input });
  }

  observeCreated(filter: DocumentClassFilter) {
    return this.createdGQL.subscribe({ filter });
  }

  observeUpdated(filter: DocumentClassFilter) {
    return this.updatedGQL.subscribe({ filter });
  }

  observeDeleted(filter: DocumentClassFilter) {
    return this.deletedGQL.subscribe({ filter });
  }

  public async buildCreateInput(parentIds: string[]) {
    // pull the 100 first doc class siblings in the same level and pre-fill almost all the settings from them
    const res = await firstValueFrom(
      this.findMany(
        { parentId: { in: parentIds } },
        { withDetail: true, pageSize: 100, sorting: [{ field: DocumentClassSortFields.OrderWeight, direction: SortDirection.Desc }] }
      )
    );
    const classes = Object.values(res.data.documentClasses.edges).map(e => e.node);
    // push to last sort order position
    const orderWeight = Math.max(classes.length, classes[0]?.orderWeight ?? 0) + 1;

    return {
      orderWeight,
      parentId: parentIds.length === 1 ? parentIds[0] : null,
      expectations: {
        selectionMode: this.findMostOccurringValue(classes.map(e => e.expectations.selectionMode)),
        possibleValue: this.findMostOccurringValue(classes.map(e => e.expectations.possibleValue))
      },
      autoPredictorMode: this.findMostOccurringValue(classes.map(e => e.autoPredictorMode)),
      blindProcessingThresholds: this.findMostOccurringValue(classes.map(e => e.blindProcessingThresholds))
    } as DocumentClassCreateDto;
  }

  private findMostOccurringValue<T>(arr: T[]): T | null {
    if (arr.length === 0) {
      return null;
    }

    const frequencyMap = new Map<string, number>();

    // Count the occurrences of each value in the array
    for (const item of arr) {
      const jsonItem = JSON.stringify(item);
      const count = frequencyMap.get(jsonItem) || 0;
      frequencyMap.set(jsonItem, count + 1);
    }

    let maxCount = 0;
    let mostOccurringValue: string | null = null;

    // Find the value with the highest occurrence
    for (const [value, count] of frequencyMap.entries()) {
      if (count > maxCount) {
        maxCount = count;
        mostOccurringValue = value;
      }
    }

    return mostOccurringValue ? JSON.parse(mostOccurringValue) : mostOccurringValue;
  }
}
